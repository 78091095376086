import { createAction, props } from '@ngrx/store';

export const loadRegistrationListState = createAction(
  '[RegistrationList] Load RegistrationList State'
);

export const setLicensePlateQuery = createAction(
  '[RegistrationList] Set license plate search query',
  props<{ licensePlateQuery: string }>(),
);



