import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

export class RsMissingTranslationHandler implements MissingTranslationHandler {
  public handle(params: MissingTranslationHandlerParams): string | Function {

    if (Object.prototype.hasOwnProperty.call(params?.interpolateParams || {}, 'ifEmpty')) {
      return params.interpolateParams!['ifEmpty' as keyof Object];
    }

    return 'Missing Key: ' + params.key;
  }
}
