/** Get the full language */
export enum Languages {
    'nl' = 'DUTCH',
    'en' = 'ENGLISH',
    'fr' = 'FRENCH',
    'de' = 'GERMAN'
}

export enum RentaTopBarDomains {
    RENTA = 'RENTA_SOLUTIONS',
    EUROPE = 'MOBEYOND'
}

export enum RentaDomains {
    RENTA = 'rentasolutions.org',
    EUROPE = 'mobeyond.eu',
    RENTA_COPY = 'Renta Solutions',
    EUROPE_COPY = 'Mobeyond'
}

export enum LanguagesCode {
    'DUTCH' = 'nl',
    'ENGLISH' = 'en',
    'FRENCH' = 'fr',
    'GERMAN' = 'de'
}

export enum Countries {
    LUXEMBOURG = 'LUXEMBOURG',
    BELGIUM = 'BELGIUM',
    FRANCE = 'FRANCE',
    GERMANY = 'GERMANY',
    THE_NETHERLANDS = 'THE_NETHERLANDS'
}
