import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class RsBlobErrorHttpInterceptor implements HttpInterceptor {
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(req)
      .pipe(
        catchError((err: HttpEvent<unknown>) => {
          if (
            err instanceof HttpErrorResponse
            && err.error instanceof Blob
            && err.error.type === 'application/json'
          ) {
            return new Observable<HttpEvent<HttpErrorResponse>>((subscriber) => {
              (<Blob>err.error).text()
                .then((value) => {
                  subscriber.error(
                    new HttpErrorResponse({
                      ...<Error>err,
                      error: JSON.parse(value)
                    })
                  );
                });
            });
          }

          return throwError(() => err);
        })
      );
  }
}
