import { AppInitializerHtmlElement } from '../components/web-component-app-initializer/app-initializer';
import { forkJoin } from 'rxjs';
import { AppInitializerErrorType } from '../components/web-component-app-initializer/models/app-initializer-error-type';
import { RsAppInitializerReplaySubject } from '../components/web-component-app-initializer/classes/RsAppInitializerReplaySubject';

export interface AppInitializerFactoryError {
  type: AppInitializerErrorType,
  message: string
}

export function appInitializerFactory(
    conditions: RsAppInitializerReplaySubject[],
    APP_INITIALIZER_HTML_ELEMENT_NAME: string,
    successCallback?: () => void
): () => {} {
  const htmlElement = document.querySelector(APP_INITIALIZER_HTML_ELEMENT_NAME) as AppInitializerHtmlElement;

  if (!htmlElement) {
    throw `<${APP_INITIALIZER_HTML_ELEMENT_NAME}> is not in the dom!`;
  }

  return (): Promise<boolean> =>
    new Promise((resolve, reject) => {
      forkJoin(conditions)
        .subscribe({
          next: () => {
            if (successCallback) {
              successCallback();
            }

            htmlElement?.remove();

            resolve(true);
          },
          error: (error: AppInitializerFactoryError) => {
            htmlElement.errorType = error.type;
            reject(error.message);
          }
        });
    });
}

