import { Pipe, PipeTransform } from '@angular/core';
import { TranslatableString } from '../../models/translatable-string';
import { TranslateService } from '@ngx-translate/core';
import { LanguagesShort } from '../../models';

/** RsTranslatePipe
 *
 * The 'rsTranslate' pipe will return a translated string value based on the selected UI language.
 * For this it expects a TranslatableString object which contains a value for each possible UI language
 *
 * @Param{TranslatableString} - value object of which to take the matching translation
 *
 * @return string - translated string
 *
 */
@Pipe({ name: 'rsTranslate', pure: false, standalone: true })
export class RsTranslatePipe implements PipeTransform {

  constructor(private readonly translateService: TranslateService) {
  }

  public transform(value?: TranslatableString | null): string | undefined {
    return value?.[this.translateService.currentLang as LanguagesShort];
  }
}
