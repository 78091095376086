import { createReducer, on } from '@ngrx/store';
import { setLicensePlateQuery } from '@registrations/registration-list/state/registration-list.actions';

export const registrationListFeatureKey = 'registrationList';

export interface RegistrationListState {
  licensePlateQuery: string;
}

export const initialState: RegistrationListState = {
  licensePlateQuery: '',
};

export const registrationListReducer = createReducer(
  initialState,

  on(setLicensePlateQuery, (currentState, { licensePlateQuery }) => {
    return {
      ...currentState,
      licensePlateQuery,
    };
  }),
);
