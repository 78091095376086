import { Environment, MenuItem, RentaTopBarDomains, SubMenuItem } from '../../models';
import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { isMobiSkin } from '../../../scripts/applicationsSkinSetter';
import { filter, takeUntil } from 'rxjs/operators';
import { TopBar, TopBarOptions } from './topbar';
import { ENVIRONMENT } from '../../modules/rs-core/rs-core.module';
import { EnvironmentConfig } from '../../modules/rs-core/models/apps-environment';

declare function TopBar(options: TopBarOptions): TopBar;

@Component({
  selector: 'rs-top-bar-menu',
  templateUrl: './rs-top-bar-menu.component.html',
  styleUrls: ['./rs-top-bar-menu.component.scss'],
  standalone: true
})
export class RsTopBarMenuComponent implements OnInit, OnDestroy {

  /** Required, application name, default = 'APP' */
  @Input() public application!: string;
  /** Optional, accepted string values [dev, tst, acc, prd], default = dev */
  @Input() public environment!: 'dev' | 'tst' | 'acc' | 'prd';
  /** Optional, boolean to render footer, default = false */
  @Input() public footer?: boolean;
  /** Optional, boolean to disable sso_token check, default = false
   * This param has currently only been added to dev and tst versions of the topbar
   **/
  @Input() public dummy?: boolean;
  /** Optional, string representing the topbar version. e.g. "1.2" **/
  @Input() public version?: string = '1.2';
  /** Optional, pass the cdn url WITH the version **/
  @Input() public cdnUrl?: string;
  /** Optional, flag which minifies the source. Default is true **/
  @Input() public minified: boolean = true;
  /** Optional, flag which will display the help button. Default is false **/
  @Input() public showHelpButton: boolean = false;
  /** @Optional will set the sorting of subMenu Items
   * @default  NO_SORTING = Will display sub menu items as it is sent
   * */
  @Input() public sortSubMenuItemsBy?: 'ASC' | 'DESC' | 'NO_SORTING' = 'NO_SORTING';
  /** Event emitted when top bar is loaded.
   *
   * Temp fix for catching logout.
   * Add event listener to button @app side
   */
  @Output() public onLoaded: EventEmitter<void> = new EventEmitter();
  /** Event emitted when user logs out. */
  @Output() public onLogout: EventEmitter<void> = new EventEmitter();
  /** Event emitted when router navigationEnd with current route url. */
  @Output() public onRouteChange: EventEmitter<string> = new EventEmitter();
  /** Event emitted when top bar help button is clicked. */
  @Output() public onHelpButtonClicked: EventEmitter<void> = new EventEmitter();
  public topBar?: TopBar;
  private jsCDN!: string;
  private cssCDN!: string;
  private translatedMenuItems?: MenuItem[];
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    @Inject(ENVIRONMENT) private readonly env: EnvironmentConfig,
  ) {
  }

  private _menuItems?: MenuItem[];

  /** Optional, array of objects as shown below, default = empty list */
  @Input()
  public set menuItems(menuItems: MenuItem[]) {
    this._menuItems = menuItems;
    this.setMenuItems();
  }

  /** @ignore */
  private static loadStylesheet(cssCDN: string): void {
    const node = document.createElement('link');
    node.href = cssCDN as string;
    node.rel = 'stylesheet';
    node.type = 'text/css';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /** @ignore */
  private static setCookie(cname: string, cvalue: string, exhours: number): void {
    const date = new Date();
    date.setTime(date.getTime() + (exhours * 60 * 60 * 1000));
    const expires = 'expires=' + date.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  public ngOnInit(): void {
    this.setTopBar();

    this.translateService
      .onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((langChangeEvent) => {
        this.setMenuItems();
        this.topBar?.switchLanguage(langChangeEvent.lang);
      });

    this.routerEventListener();
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private routerEventListener(): void {
    this.router
      .events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        const urlAfterRedirects = (event as NavigationEnd).urlAfterRedirects;
        this.onRouteChange.emit(urlAfterRedirects);

        if (this._menuItems) {
          this.setSelectedMenuItemForRoute(this._menuItems, urlAfterRedirects);
        }
      })
  }

  private sortSubMenuItems(): void {

    this.translatedMenuItems
      ?.forEach(element => {
        if (element.subMenuItems) {
          switch (this.sortSubMenuItemsBy) {
            case 'DESC':
              element.subMenuItems.sort((sebuMenuItemA, subMenuItemB) => (sebuMenuItemA.label < subMenuItemB.label) ? 1 : ((subMenuItemB.label < sebuMenuItemA.label) ? -1 : 0));
              break;
            case 'ASC':
            default:
              element.subMenuItems.sort((sebuMenuItemA, sebuMenuItemB) => (sebuMenuItemA.label > sebuMenuItemB.label) ? 1 : ((sebuMenuItemB.label > sebuMenuItemA.label) ? -1 : 0));
              break;
          }
        }
      });
  }

  private filterMenuItems(): void {
    this._menuItems =
      this._menuItems
        ?.filter((item): boolean => {
          const
            canDisplay = item.display ?? true,
            hasEnvironment = item.rsIfEnvironment ? item.rsIfEnvironment?.includes(this.env.environment as Environment) : true;

          return canDisplay && hasEnvironment;
        });

    // Filter SubmenuItems
    this._menuItems?.forEach((menuItem) => {
      if (menuItem.subMenuItems) {
        menuItem.subMenuItems = menuItem.subMenuItems
          ?.filter((item): boolean => {
            const
              canDisplay = item.display ?? true,
              hasEnvironment = item.rsIfEnvironment ? item.rsIfEnvironment?.includes(this.env.environment as Environment) : true;

            return canDisplay && hasEnvironment;
          });
      }
    });
  }

  private setMenuItems(): void {

    if (!this.topBar?.loaded) {
      return;
    }

    this.translatedMenuItems = [];

    if (this.hasMenuItems()) {
      this.getMenuTranslation();

      if (this.sortSubMenuItemsBy !== 'NO_SORTING') {
        this.sortSubMenuItems();
      }
    }

    this.topBar.setMenuItems(this.translatedMenuItems);

    if (this._menuItems) {
      this.setSelectedMenuItemForRoute(this._menuItems, this.router.url);
    }

    this.setNavListeners(this.router);
  }

  private setSelectedMenuItemForRoute(menuItems: MenuItem[], newUrl: string): void {
    const newUrlStripped = newUrl.split('?')[0].replaceAll('/', '');

    menuItems?.forEach((menuItem) => {
      const menuItemBaseMenuUrl = menuItem.baseMenuUrl || [];

      if (!menuItem.baseMenuUrl?.length) {
        menuItemBaseMenuUrl.push(menuItem.url!);
      }

      menuItemBaseMenuUrl?.forEach((currentMenuItem) => {
        const menuItemUrl = currentMenuItem?.replaceAll('/', '');

        if (menuItemUrl && newUrlStripped.startsWith(menuItemUrl)) {
          this.topBar?.selectMenuItem(menuItem.id)
        }
      });


      if (menuItem.subMenuItems) {
        this.setSelectedMenuItemForRoute(menuItem.subMenuItems, newUrl);
      }
    });
  }

  private hasMenuItems(): boolean {
    this.filterMenuItems();

    return !!this._menuItems?.length || 0 > 0;
  }

  private getMenuTranslation(): void {
    this.translatedMenuItems = this._menuItems?.map(
      (menuItem) => {
        return {
          ...menuItem,
          label: this.getTranslatedLabelWithSuffix(menuItem),
          subMenuItems: menuItem.subMenuItems?.map(subMenuItem => {
            return {
              ...subMenuItem,
              label: this.getTranslatedLabelWithSuffix(subMenuItem),
            }
          })
        }
      }
    );
  }

  private setTopBar(): void {
    this.setFileLocations();
    this.loadScript(this.jsCDN);
    RsTopBarMenuComponent.loadStylesheet(this.cssCDN);
  }

  /** @ignore */
  private loadScript(jsCDN: string): void {
    const node = document.createElement('script');
    node.src = jsCDN as string;
    node.type = 'text/javascript';
    node.async = true;
    node.onload = (): void => {
      this.initTopbar();
    };
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  /** @ignore */
  private initTopbar(): void {
    TopBar({
      application: this.application,
      environment: this.environment,
      footer: !!this.footer,
      dummy: !!this.dummy,
      helpButton: this.showHelpButton,
      domain: isMobiSkin ? RentaTopBarDomains.EUROPE : RentaTopBarDomains.RENTA,
      topbarLoadedListeners: [
        {
          key: 'setMenuItemsTranslations',
          listener: this.onTopBarLoaded.bind(this)
        }
      ],
      loggedOutListeners: [
        {
          key: 'logout',
          listener: (): void => {
            this.emitOnLogout();
          }
        }
      ],
      helpButtonClickedListeners: [
        {
          key: 'helpButtonClickedListeners',
          listener: (): void => {
            this.helpButtonListener();
          }
        }
      ]
    });
  }

  private helpButtonListener(): void {
    this.onHelpButtonClicked.emit();
  }

  private onTopBarLoaded(topBar: TopBar): void {
    this.topBar = topBar;
    this.topBar.loaded = true;
    this.setMenuItems();
    this.onLoaded.emit();
  }

  private emitOnLogout(): void {
    this.onLogout.emit();
  }

  /** @ignore */
  private setFileLocations(): void {
    if (this.cdnUrl) {
      this.cssCDN = `${this.cdnUrl}css/topbar${this.minified ? '.min' : ''}.css`;
      this.jsCDN = `${this.cdnUrl}js/topbar${this.minified ? '.min' : ''}.js`;
    } else {
      switch (this.environment.toLowerCase()) {
        case 'prd':
          this.cssCDN = `https://cdn.rentasolutions.org/topbar/v${this.version}/css/topbar.min.css`;
          this.jsCDN = `https://cdn.rentasolutions.org/topbar/v${this.version}/js/topbar.min.js`;
          break;
        case 'acc':
          this.cssCDN = `https://cdn.rentasolutions.org/topbar/acc/v${this.version}/css/topbar.min.css`;
          this.jsCDN = `https://cdn.rentasolutions.org/topbar/acc/v${this.version}/js/topbar.min.js`;
          break;
        case 'tst':
        case 'dev':
        default:
          this.cssCDN = `https://s3.eu-central-1.amazonaws.com/cdn.rentasolutions/topbar/tst/v${this.version}/css/topbar.css`;
          this.jsCDN = `https://s3.eu-central-1.amazonaws.com/cdn.rentasolutions/topbar/tst/v${this.version}/js/topbar.js`;
      }
    }
  }

  /** @ignore */
  private setNavListeners(router: Router): void {
    if (router) {
      const elements = document.querySelectorAll('.navigation-link');
      elements.forEach(el => {
        el.addEventListener('click', (event): void => {
          event.preventDefault();
          router.navigate([el.getAttribute('href')]);
        });
      });
    }
  }

  private getTranslatedLabelWithSuffix(subMenuItem: SubMenuItem | MenuItem): string {
    const suffix = subMenuItem.labelSuffix ? ' ' + subMenuItem.labelSuffix : '';
    return subMenuItem.doNotTranslateLabel ? subMenuItem.label : this.translateService.instant(subMenuItem.label) + suffix;
  }
}
