import { DatePipe } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Injectable({
  providedIn: 'root',
})
export class RsMomentDateAdapter extends MomentDateAdapter {

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
  }

  public override format(date: Moment, displayFormat: string): string {
    if (displayFormat === 'l') {
      return new DatePipe(moment.locale()).transform(date.toISOString(), 'shortDate')!;
    } else {
      return new DatePipe(moment.locale()).transform(date.toISOString(), 'MMM yyyy')!;
    }
  }
}
