import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AddRegistrationRequest, EditRegistrationRequest, FetchedRegistration } from '@shared/models/registration';
import { UUID } from '@rs-shared-lib/models';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) {
  }

  public getRegistration(id: string): Observable<FetchedRegistration> {
    return this.http.get<FetchedRegistration>(`${environment.apiUrl}/registration/${id}`);
  }

  public addRegistration(addRegistrationRequest: AddRegistrationRequest): Observable<string> {
    return this.http.post<string>(`${environment.apiUrl}/registration`, addRegistrationRequest);
  }

  public editRegistration(id: string, editRegistrationRequest: EditRegistrationRequest): Observable<string> {
    return this.http.put<string>(`${environment.apiUrl}/registration/${id}`, editRegistrationRequest);
  }

  public deleteRegistration(id: UUID): Observable<string> {
    return this.http.delete<string>(`${environment.apiUrl}/registration/delete/${id}`);
  }

}
