import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as Sentry from '@sentry/angular-ivy';
import { RsAuthenticationService } from '../../modules/rs-authentication/services/rs-authentication.service';
import { RS_CURRENT_APP_NAME } from '../../rs-core.module';
import { UserInfoCookieAppName } from '../../modules/rs-authentication/enums/UserInfoCookieAppName.enum';
import {
  RsMessagesHandlerService
} from '../../../../components/rs-messages-handler/services/rs-messages-handler.service';
import { SKIP_RS_MESSAGE_HANDLER_ERRORS_TOKEN } from './http-context-tokens/skip-rs-message-handler-errors';

@Injectable()
export class RsHttpErrorsInterceptor implements HttpInterceptor {
  private readonly ENABLED_APP_NAMES = [
    'ORD',
    'MGT',
    'FMS',
    'MRT',
  ];

  constructor(
		private readonly rsAuthenticationService: RsAuthenticationService,
		@Inject(RS_CURRENT_APP_NAME) private readonly currentAppName: keyof typeof UserInfoCookieAppName,
		private readonly rsMessagesHandlerService: RsMessagesHandlerService
  ) {
  }

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: Error | HttpErrorResponse) => {
        if (error instanceof HttpErrorResponse) {
          this.captureSentryMessage(error);

          if (this.ENABLED_APP_NAMES.includes(this.currentAppName) && !request.context.get(SKIP_RS_MESSAGE_HANDLER_ERRORS_TOKEN)) {
            this.rsMessagesHandlerService.showBeErrorMsg({ httpError: error });
          }
        }

        throw error;
      }),
    );
  }

  private captureSentryMessage(httpError: HttpErrorResponse): void {
    Sentry.captureMessage(
      'http-error',
      scope => scope
        .addBreadcrumb({
          type: 'error',
          category: 'error',
          level: 'error',
          message: httpError.error.message,
        })
        .setTag('app.error-type', 'http-error')
        .setExtra('error', httpError)
        .setLevel('error')
        .setContext('error details-section', {
          'error-code': httpError.status,
          'backend-detailed-error': JSON.stringify(httpError.error),
        }),
    );
  }
}
