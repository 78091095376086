import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserInfoCookieAppName } from '../enums/UserInfoCookieAppName.enum';
import { UserInfoCookie } from '../models/userInfoCookie';
import { RS_CURRENT_APP_NAME } from '../../../rs-core.module';

@Injectable({
  providedIn: 'root',
})
export class RsAuthenticationService {

  constructor(
    private readonly cookieService: CookieService,
    private readonly jwtHelperService: JwtHelperService,
    @Inject(RS_CURRENT_APP_NAME) private readonly currentAppName: keyof typeof UserInfoCookieAppName
  ) {
  }

  /** Check if user has access to this app.
   *
   * !!! Temp solution with cookies !!!
   * */
  public readonly canUserAccessApp = (): boolean => {
    if (this.cookieService.get('user-info').length === 0) { return true }

    return Boolean(this.getUserAllowedApps().includes(this.currentAppName));
  }

  private getUserAllowedApps(): UserInfoCookie['apps'] {
    const
      decodedUserInfoCookie: UserInfoCookie = JSON.parse(this.jwtHelperService.urlBase64Decode(this.cookieService.get('user-info'))),
      apps: UserInfoCookie['apps'] = decodedUserInfoCookie.apps;

    return apps ? apps.map((app) => UserInfoCookieAppName[app]) : [];
  }
}


