<rs-top-bar-menu
  (onHelpButtonClicked)='acceptTutorialDialog()'
  (onLoaded)='isFirstTimeUserConnects()'
  [application]="'FMS'"
  [cdnUrl]="topBarCdn"
  [dummy]="topBarEnvironment==='dev'"
  [environment]="topBarEnvironment"
  [footer]="false"
  [menuItems]="menuItems"
  [showHelpButton]='true'
/>

<rs-loader />

<router-outlet />

<rs-footer
  [cookiesKey]="'FOOTER.COOKIES'"
  [disclaimerKey]="'FOOTER.DISCLAIMER'"
  [needHelpKey]="'FOOTER.NEED_HELP'"
/>

<ng-template #introJsDialog>
  <button
    data-testid="btn-close"
    mat-dialog-close
    mat-icon-button
    matSuffix
  >
    <mat-icon>close</mat-icon>
  </button>

  <div mat-dialog-content>
    <img src="assets/img/intro-js/welcome-screen-pic.jpg" />

    <div class='text-center p-20px'>
      <h2 class='mb-15px'>{{ 'INTROJS_TUTORIAL.WELCOME_TO_FMS' | translate }}</h2>
      <p [innerHTML]='"INTROJS_TUTORIAL.ACCEPT_TUTORIAL_TEXT" | translate'></p>
    </div>
  </div>

  <div
    class='center-action-buttons'
    mat-dialog-actions
  >
    <button
      data-testid="do-not-launch-tuto"
      class='rs-btn--secondary px-5 me-10px'
      mat-button
      mat-dialog-close
    >
      No
    </button>

    <button
      (click)='startTutorial()'
      class="rs-btn--primary position-relative px-5"
      data-testid='launch-tuto'
      mat-button
      mat-dialog-close
    >
      Yes
    </button>
  </div>
</ng-template>