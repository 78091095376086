import { Subscription } from 'rxjs';
import { HttpErrorResponse, HttpResponse, HttpResponseBase } from '@angular/common/http';

export class RsFile {
  public id?: string;
  public data!: File;
  public isFileTypeAllowed = true;
  public isFileEmpty = false;
  public maxFilesSizeExceeded = false;
  public maxFileSizeExceeded = false;
  public maxTotalFilesExceeded = false;
  public error = false;
  public errorDescription = '';
  public progress = 0;
  public httpRequestSubscriptionHolder$!: Subscription;
  public description = '';
  public httpResponse?: HttpResponse<HttpResponseBase | HttpErrorResponse>;
  public 'display-error-info': boolean;
}
