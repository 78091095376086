import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en-GB';
import localeDe from '@angular/common/locales/de';
import { environment } from '@src/environments/environment';
import { errorHandlerFactory } from '@core/error-handlers/error-handler-factory';
import { RsCoreModule, } from '@rs-shared-lib/modules/rs-core/rs-core.module';
import { UserInfoCookieAppName } from '@rs-shared-lib/modules/rs-core/modules/rs-authentication/enums/UserInfoCookieAppName.enum';
import { MatIconRegistry } from '@angular/material/icon';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';
import { CustomMatIconsModule } from '@rs-shared-lib/modules/rs-core/modules/custom-mat-icons.module';
import { JWT_OPTIONS } from '@auth0/angular-jwt';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntlService } from '@core/services/material/custom-mat-paginator-intl';

registerLocaleData(localeFr);
registerLocaleData(localeNl);
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe);

@NgModule({ imports: [
  CustomMatIconsModule,
  BrowserAnimationsModule,
  BrowserModule,
  RsCoreModule
    .forRoot({
      appName: UserInfoCookieAppName.FMS,
      environment
    })
],
providers: [
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
  { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntlService },
  provideEnvironmentNgxMask(),
  { provide: ErrorHandler, useFactory: errorHandlerFactory },
  provideHttpClient(withInterceptorsFromDi())
] })
export class CoreModule {
  private readonly customIconsFolder: string;
  private customIconsArray: string[];

  /**
   * Ensures that the CoreModule can only be loaded once.
   *
   * https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
   *
   * @param matIconRegistry
   * @param domSanitizer
   * @param translateService
   * @param dateAdapter
   * @param parentModule
   */
  public constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<Date>,
    @Optional() @SkipSelf() parentModule?: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it only once!');
    }

    /** Custom icons folder path */
    this.customIconsFolder = 'assets/img/custom-mat-icons/';

    /** Custom icons file name list */
    this.customIconsArray = [
      'download',
      'download-outline',
      'file-image',
      'file-outline',
      'funnel',
      'speak-no-evil-monkey',
      'emoticon-dead',
      'home-city-outline',
      'information-outline',
      'hand-pointing-up',
      'cursor-pointer',
      'gesture-swipe',
      'flash-circle',
      'clapping',
      'arrow-right-alt',
      'file-download-outline',
      'file-upload-outline',
      'driver-50',
      'fine-50',
      'file-upload',
      'upload',
      'file-check-outline'
    ];

    /** Add custom icons to mat icon registry */
    this.customIconsArray.forEach((iconName): void => {
      this.matIconRegistry.addSvgIcon(
        `${iconName}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.customIconsFolder}${iconName}.svg`)
      );
    });

    /** Adapt local for datepicker and change the mask based on local */
    this.translateService.onLangChange
      .subscribe((event: LangChangeEvent): void => {
        this.dateAdapter.setLocale(event.lang);
      });
  }
}
