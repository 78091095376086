<div
  *ngIf="showSpinner || showSpinners "
  [style.background-color]="'rgba(255,255, 255, ' + backgroundOpacity + ')'"
  id="spinnerOverlay"
>
  <div class="center">
    <div *ngIf="isMobiSkin; else rsLoader">
      <div id='mobi-spinner-container'>

        <mat-spinner
          color='primary'
          diameter='100'
          mode='indeterminate'
          strokeWidth='1'
        ></mat-spinner>
      </div>
    </div>

    <ng-template #rsLoader>
      <img
        [src]="'assets/loaders/rs-loader-light-1.svg'"
        class="me-5px"
      />
    </ng-template>

    <h2
      [innerHTML]="currentMessage | translate"
      class="loaderTitle"
    >
    </h2>
  </div>
</div>