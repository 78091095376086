import { createAction, props } from '@ngrx/store';

export const updateRequestListPagingOptions = createAction(
  '[RequestList] Update Request List Paging Filters',
  props<{ size: number, page: number }>(),
);

export const updateRequestListSanctioningPartyId = createAction(
  '[RequestList] Update Request List Sanctioning Party Id Query',
  props<{ sanctioningPartyId: string }>(),
);
