import { RsBackEndError } from '../../../models/back-end--error';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RsMessageHandlerComponent } from '../rs-message-handler/rs-message-handler.component';
import { RsMessagesHandlerOptions } from '../models/rs-messages-handler-options';
import { RsMessageHandlerType } from '../models/rs-message-handler-data';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RsMessagesHandlerService {

  constructor(private readonly toastr: ToastrService) {
  }

  /** Will display your message styled as an error message
	 *
	 * @param options.isPlainText boolean (Optional) Default false. If true, will display the full text without translation
	 * @param options.msg string (Optional) translatable key - Default value = 'ERROR_MSG.GLOBAL' translatable key. Has to be defined a project level!
	 * @param options.duration millisecond as number - (Optional) - Default = 4000 - 0 = never dismiss automatically
	 */
  public showErrorMsg(
    options?: RsMessagesHandlerOptions
  ): void {
    this.showRsMessageHandler('error', options);
  }

  /** If httpError.error is provided in a correct RsBackEndError format,
	 *
	 * it will display Backend error messages styled as an error message
	 *
	 * else will fall back on showErrorMsg()
	 *
	 * This is based on a new convention of error formats. Not yet implemented everywhere at BE side
	 *
	 * @param options.msg string (Optional) translatable key - Default value = 'ERROR_MSG.GLOBAL' translatable key. Has to be defined a project level!
	 *
	 * @param options.duration millisecond as number - (Optional) - Default = 4000 - 0 = never dismiss automatically
	 *
	 * @param options.isPlainText boolean - (Optional) -  Default false. If true, will display the full text without translation
	 *
	 * @param options.httpError HttpErrorResponse - will display the translations provided by the BE.
	 */
  public showBeErrorMsg(
    options: RsMessagesHandlerOptions & { httpError: HttpErrorResponse | null; }
  ): void {
    const errors: RsBackEndError[] = this.fallBackIfWrongBeErrorFormat(options.httpError?.error);

    this.showRsMessageHandler(
      'errors-array',
      {
        duration: 4000,
        ...options
      },
      errors
    );
  }

  /** Will display your message styles as a success message
	 *
	 * @param options.msg string (Optional) translatable key - Default value = 'SUCCESS_MSG.GLOBAL' translatable key. Has to be defined a project level!
	 * @param options.isPlainText boolean (Optional) Default false. If true, will display the full text without translation
	 * @param options.duration  millisecond as number - (Optional) - Default = 4000 - 0 = never dismiss automatically
	 */
  public showSuccessMsg(
    options?: RsMessagesHandlerOptions
  ): void {
    this.showRsMessageHandler('success', options);
  }

  private showRsMessageHandler(
    rsMessageHandlerType: RsMessageHandlerType,
    options?: RsMessagesHandlerOptions,
    beErrors?: RsBackEndError[]
  ): void {

    const defaultOptions: RsMessagesHandlerOptions = {
      msg: null,
      isPlainText: false,
      duration: 4000,
      ...options
    };

    this.toastr.show(
      defaultOptions.msg || '',
      '',
      {
        toastComponent: RsMessageHandlerComponent,
        closeButton: true,
        toastClass: `ngx-toastr rs-message-handler rs-message-handler-${rsMessageHandlerType}`,
        timeOut: defaultOptions.duration,
        positionClass: 'toast-top-right',
        tapToDismiss: false,
        progressBar: defaultOptions.duration != 0,
        extendedTimeOut: defaultOptions.duration,
        onActivateTick: true,
        enableHtml: true,
        payload: {
          type: rsMessageHandlerType,
          isPlainText: defaultOptions.isPlainText,
          listOfErrors: beErrors
        }
      }
    );
  }

  /** As BE errors convention format is still not implemented everywhere,
	 * we should fall back into the global error message in case of bad format
	 **/
  private fallBackIfWrongBeErrorFormat(backendErrors: unknown): RsBackEndError[] {
    return Array.isArray(backendErrors) && backendErrors.length ?
      backendErrors
      : [
        {
          fieldName: '',
          translations: {
            fr: '',
            en: '',
            de: '',
            nl: ''
          },
          error: '',
          errorKey: '',
          errorId: null
        }
      ];
  }
}
