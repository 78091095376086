/** languages = 'DUTCH' | 'ENGLISH' | 'FRENCH' | 'GERMAN' */
import { FormControl } from '@angular/forms';

export type Language = 'DUTCH' | 'ENGLISH' | 'FRENCH' | 'GERMAN';

export type Country = 'LUXEMBOURG' | 'BELGIUM' | 'FRANCE' | 'GERMANY' | 'THE_NETHERLANDS';

export type LanguagesShort = 'nl' | 'en' | 'fr' | 'de';

export type CountriesIsoCode =
	'AT'
	| 'BE'
	| 'BG'
	| 'HR'
	| 'CY'
	| 'CZ'
	| 'DK'
	| 'EE'
	| 'FI'
	| 'FR'
	| 'DE'
	| 'GR'
	| 'HU'
	| 'IE'
	| 'IT'
	| 'LV'
	| 'LU'
	| 'MC'
	| 'NL'
	| 'PL'
	| 'PT'
	| 'SK'
	| 'SI'
	| 'ES'
	| 'SE'
	| 'CH'
	| 'GB'
	| 'RO'
	| string;

export type OrderStatus =
	'NEW'
	| 'MODIFIED'
	| 'REQUEST_TO_CANCEL'
	| 'ACCEPTED'
	| 'ORDERED'
	| 'VEHICLE_IN'
	| 'LICENSE_PLATE_ORDERED'
	| 'DELIVERED'
	| 'CANCELLED'
	| 'ARCHIVED'
	| 'NOT_ACCEPTED';

/** dateString (ex: 2002-07-03)  */
export type DateString = string;

/** Ex: 1563870361  */
export type Timestamp = number;

/** Ex: 2020-09-30  */
export type SimpleDate = string;

/** Ex: 1416438000000 */
export type EpochTimestamp = number;

/** Ex: 1416438000000 | '1416438000000' */
export type UnixTimestamp = string | number;

/** isoString (ex: 2011-10-05T14:48:00.000Z) */
export type IsoString = string;

/** dateTime (ex: 2019-08-24T14:15:22Z) */
export type DateTime = string;

/** UUID ex: 72229374-853d-40cc-94a6-8ac6a83ce28e */
export type UUID = string;

export type URL = string;

export type Email = string;

/** userRole = '' | 'ADMIN' | 'SYSTEM' | 'DEALER' | 'LEASING_COMPANY' | 'LEASING_COMPANY_WITH_AUTHORIZATION' | 'INSURANCE_BROKER' | 'INSURANCE_BROKER_WITH_AUTHORIZATION' | 'INSURANCE_COMPANY_MANAGEMENT_USER' */
export type UserRole =
	''
	| 'ADMIN'
	| 'SYSTEM'
	| 'DEALER'
	| 'LEASING_COMPANY'
	| 'LEASING_COMPANY_WITH_AUTHORIZATION'
	| 'INSURANCE_BROKER'
	| 'INSURANCE_BROKER_WITH_AUTHORIZATION'
	| 'INSURANCE_COMPANY_MANAGEMENT_USER';

/** Returns file as base64String */
export type Base64String = string;

/** Milliseconds as number */
export type Millisecond = number;

/** document.querySelector('[QuerySelector]') */
export type QuerySelector = string;

export type NgxTranslateKey = string;

/** Returns: type | null - Ex: nullable<string> = string | null, nullable<number> number | null */
export type Nullable<T> = T | null;

export type Environment = 'development' | 'mock' | 'test' | 'acceptance' | 'production';

/** All properties can be passed type OR null. Event nested ones */
export type DeepNullableObjProp<T> = {
	[k in keyof T]: DeepNullableObjProp<T[k]> | null
}

/** ### Generic type for typed FormGroup
 *
 * Will generate a new type from provided interface
 *
 * **from** [key]: type
 *
 * **to**  [key]: FormControl<type | null>
 */
export type RsTypedFormGroup<Interface> = {
	[key in keyof Interface]: FormControl<Interface[key] | null>;
}

export type ColumnWithLabelAndId = { label: string; columnId: string; };

export type ColumnSpecification = string | ColumnWithLabelAndId;

export type SortDirection = 'ASC' | 'DESC';

/** Will apply Partial on nested props */
export type NestedPartial<T> = {
	[P in keyof T]?: NestedPartial<T[P]>;
};

/** **Assert object literal type only!**
 * @example { prop: '', prop2: '' }
 *
 * Why? Typescript does not provide this type and
 * - object (lowercase “o”) in TypeScript: non-primitive values
 * - Object (uppercase “O”) in TypeScript: instances of class Object
 *
 * */
export type RsObjectLiteral = object & { length?: never; };
