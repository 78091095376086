<ct-app-initializer>

  <ct-loading-container *ngIf='!_errorType; else translationFileErrorMessage'>
    <ct-label>
      {{ translations.loading[currentLanguage] }}...
    </ct-label>
    <ct-animation></ct-animation>
  </ct-loading-container>

  <ng-template #translationFileErrorMessage>
    <ct-eyes-hit-area-container>
      <ct-hit-box id='a-1'></ct-hit-box>
      <ct-hit-box id='a-2'></ct-hit-box>
      <ct-hit-box id='a-3'></ct-hit-box>
      <ct-hit-box id='a-4'></ct-hit-box>
      <ct-hit-box id='a-5'></ct-hit-box>
      <ct-hit-box id='b-1'></ct-hit-box>
      <ct-hit-box id='b-2'></ct-hit-box>
      <ct-hit-box id='b-3'></ct-hit-box>
      <ct-hit-box id='b-4'></ct-hit-box>
      <ct-hit-box id='b-5'></ct-hit-box>
      <ct-hit-box id='c-1'></ct-hit-box>
      <ct-hit-box id='c-2'></ct-hit-box>
      <ct-hit-box id='c-3'></ct-hit-box>
      <ct-hit-box id='c-4'></ct-hit-box>
      <ct-hit-box id='c-5'></ct-hit-box>
      <ct-hit-box id='d-1'></ct-hit-box>
      <ct-hit-box id='d-2'></ct-hit-box>
      <ct-hit-box id='d-3'></ct-hit-box>
      <ct-hit-box id='d-4'></ct-hit-box>
      <ct-hit-box id='d-5'></ct-hit-box>
      <ct-hit-box id='e-1'></ct-hit-box>
      <ct-hit-box id='e-2'></ct-hit-box>
      <ct-hit-box id='e-3'></ct-hit-box>
      <ct-hit-box id='e-4'></ct-hit-box>
      <ct-hit-box id='e-5'></ct-hit-box>

      <ct-eyes-container>
        <ct-sorry class='font-big-blue animate'>
          {{ translations.translationsError.sorry[currentLanguage] }}
        </ct-sorry>
        <div class='eye'>
          <div class='pupil'></div>
        </div>
        <div class='eye'>
          <div class='pupil'></div>
        </div>
        <div class='eyelid'></div>
        <div class='eyelid'></div>


        <ng-container [ngSwitch]='_errorType'>
          <ng-container *ngSwitchCase="'TRANSLATION'">
            <ct-translation-file-error-message>
              <p
                [innerHTML]='translations.translationsError.msg[currentLanguage]'
                class='font-big-blue'
              >
              </p>
              <button
                (click)='reloadPage()'
                mat-btn
              >
                {{ translations.translationsError.buttonCopy[currentLanguage] }}
              </button>
            </ct-translation-file-error-message>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ct-translation-file-error-message [ngStyle]="{ bottom: '100px' }">
              <p
                [innerHTML]='translations.translationsError.pageNotFound[currentLanguage]'
                class='font-big-blue'
              >
              </p>
            </ct-translation-file-error-message>
          </ng-container>
        </ng-container>

      </ct-eyes-container>
    </ct-eyes-hit-area-container>

    <ct-tv-noise>
      <svg>
        <filter id='noise'>
          <feTurbulence id='turbulence'>
            <animate
              attributeName='baseFrequency'
              dur='50s'
              repeatCount='indefinite'
              values='0.9 0.9;0.8 0.8; 0.9 0.9'
            ></animate>
          </feTurbulence>
          <feDisplacementMap
            in='SourceGraphic'
            scale='60'
          ></feDisplacementMap>
        </filter>
      </svg>
    </ct-tv-noise>
  </ng-template>
</ct-app-initializer>