import { RentaDomains } from '../lib/models/enums';

export let
  isMobiSkin = false,
  rsSkinPrefix: 'mobi' | 'rs';

export function setAppSkin(): void {
  isMobiSkin = window.location.hostname.indexOf(RentaDomains.EUROPE) >= 0;
  // @ts-ignore
  isMobiSkin = window.Cypress ? window.Cypress.env('isMobiSkin') ?? isMobiSkin : isMobiSkin;
  rsSkinPrefix = isMobiSkin ? 'mobi' : 'rs';
  setAppTheme();
}

function setAppTheme(): void {
  document.getElementsByTagName('body')[0].setAttribute('app-theme', rsSkinPrefix);

  // append correct favicon
  const favicon: HTMLLinkElement = document.createElement('link');
  favicon.rel = 'icon';
  favicon.type = 'image/x-icon';
  favicon.href = `assets/favicons/${rsSkinPrefix}-favicon.ico`;
  document.getElementsByTagName('head')[0].append(favicon);
}

export function forceAppTheme(forceSkin: 'mobi' | 'rs'): void {
  isMobiSkin = forceSkin === 'mobi';
  rsSkinPrefix = isMobiSkin ? 'mobi' : 'rs';
  setAppTheme();
}
