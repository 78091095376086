import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class RsAuthenticationInterceptor implements HttpInterceptor {

  public readonly intercept = this._intercept;

  private _intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cloned = request.clone({ withCredentials: request.url.indexOf('https://translation.') !== 0 });
    return next.handle(cloned);
  }
}


