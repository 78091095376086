import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isMobiSkin } from '../../../../scripts/applicationsSkinSetter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RsLoaderService } from '../services/rs-loader.service';

@Component({
  selector: 'rs-loader',
  templateUrl: './rs-loader.component.html',
  styleUrls: ['./rs-loader.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatProgressSpinnerModule
  ]
})
export class RsLoaderComponent implements OnInit, OnDestroy {

  @Input() public showSpinners: boolean = false;

  public showSpinner: boolean = false;
  public readonly isMobiSkin = isMobiSkin;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public constructor(
    private rsLoaderService: RsLoaderService
  ) {
  }

  public get currentMessage(): string {
    return this.rsLoaderService.currentMessage ?? '';
  }

  public get backgroundOpacity(): number {
    return this.rsLoaderService.backgroundOpacity;
  }

  public ngOnInit(): void {
    this.rsLoaderService
      .showSpinner
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (doShow) => {
          const addRemove = doShow ? 'add' : 'remove';
          document.getElementsByTagName('body')[0].classList[addRemove]('no-scroll');
          this.showSpinner = doShow;
        }
      })
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
