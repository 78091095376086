import { createReducer, on } from '@ngrx/store';
import { RequestStatusEnum } from '@app/fines/shared/models/enums/request-status.enum';
import { FinesListFilters } from '@fines/shared/models/fines-list-filters';
import { updateRequestListPagingOptions, updateRequestListSanctioningPartyId } from '@app/fines/list/state/list.actions';

export const defaultRequestListFilters = {
  status: RequestStatusEnum.OPEN,
  size: 5,
  page: 1,
  sanctioningPartyId: '',
};

export interface RequestListState {
  requestListFilters: FinesListFilters;
}

export const initialRequestListState: RequestListState = {
  requestListFilters: defaultRequestListFilters,
};

export const listReducer = createReducer(
  initialRequestListState,

  on(updateRequestListPagingOptions, (currentState, {
    size, page,
  }) => {
    const newPage = currentState.requestListFilters.size !== size ? 1 : page;

    return {
      ...currentState,
      requestListFilters: {
        ...currentState.requestListFilters,
        size,
        page: newPage,
      },
    };
  }),

  on(updateRequestListSanctioningPartyId, (currentState, { sanctioningPartyId }) => {
    const partyIdHasChanged = currentState.requestListFilters.sanctioningPartyId !== sanctioningPartyId;

    const initialSize = initialRequestListState.requestListFilters.size;
    const initialPage = initialRequestListState.requestListFilters.page;
    const currentSize = currentState.requestListFilters.size;
    const currentPage = currentState.requestListFilters.page;

    return {
      ...currentState,
      requestListFilters: {
        ...currentState.requestListFilters,
        status: sanctioningPartyId ? null : RequestStatusEnum.OPEN,
        sanctioningPartyId,
        size: partyIdHasChanged ? initialSize : currentSize,
        page: partyIdHasChanged ? initialPage : currentPage,
      },
    };
  }),
);
