/** RsBackEndError format agreed as a convention between RS Frontend and Backend
 *
 * @attribute fieldName - json attribute name where error comes from
 *
 * @attribute translations - Translated message to display to user
 *
 * @attribute error - Comprehensive error explanation for FE dev
 *
 * @attribute errorKey - BE error key
 *
 * @attribute errorId - BE tracking error uid
 */
export interface RsBackEndError {
  fieldName: string;
  translations: { fr: string; en: string; de: string; nl: string; };
  error: string;
  errorKey: string;
  errorId?: string | null;
}
