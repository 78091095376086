import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({})
export class CustomMatIconsModule {
  private readonly customIconsFolder: string;
  private customIconsArray: string[];

  /**
     * Ensures that the CoreModule can only be loaded once.
     *
     * https://angular.io/guide/singleton-services#prevent-reimport-of-the-greetingmodule
     *
     * @param parentModule
     */
  public constructor(
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        @Optional() @SkipSelf() parentModule?: CustomMatIconsModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it only once!');
    }

    /** Custom icons folder path */
    this.customIconsFolder = 'assets/img/custom-mat-icons/';

    /** Custom icons file name list */
    this.customIconsArray = [
      'download',
      'download-outline',
      'file-image',
      'file-outline',
      'funnel',
      'speak-no-evil-monkey',
      'emoticon-dead',
      'home-city-outline',
      'information-outline',
      'hand-pointing-up',
      'cursor-pointer',
      'gesture-swipe',
      'flash-circle',
      'clapping',
      'arrow-right-alt',
      'file-download-outline',
      'file-upload-outline',
      'driver-50',
      'fine-50',
      'file-upload',
      'upload',
      'file-check-outline'
    ];

    /** Add custom icons to mat icon registry */
    this.customIconsArray.forEach((iconName): void => {
      this.matIconRegistry.addSvgIcon(
        `${iconName}`,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.customIconsFolder}${iconName}.svg`)
      );
    });
  }
}
