import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RsAuthenticationInterceptor } from './interceptors/rs-authentication.interceptor';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RsAuthenticationInterceptor,
      multi: true
    },
    JwtHelperService,
    CookieService,
    {
      provide: JWT_OPTIONS,
      useValue: JWT_OPTIONS
    }
  ]
})
export class RsAuthenticationModule {
}
