import { CUSTOM_ELEMENTS_SCHEMA, InjectionToken, Injector, NgModule, Optional, SkipSelf } from '@angular/core';
import { AppInitializerComponent } from './app-initializer.component';
import { createCustomElement } from '@angular/elements';
import { AppInitializerHtmlElement } from './app-initializer';
import { CommonModule } from '@angular/common';

export const RS_WEB_COMP_APP_INITIALIZER_HTML_ELEMENT_NAME = new InjectionToken<'rs-wc-app-initializer'>('rs-wc-app-initializer');

@NgModule({
  declarations: [AppInitializerComponent],
  imports: [CommonModule],
  providers: [
    {
      provide: RS_WEB_COMP_APP_INITIALIZER_HTML_ELEMENT_NAME,
      useValue: 'rs-wc-app-initializer'
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class RsWebComponentAppInitializerModule {
  private readonly APP_INITIALIZER_HTML_ELEMENT_NAME: string;
  private readonly htmlElement: AppInitializerHtmlElement;

  constructor(
    private readonly injector: Injector,
    @Optional() @SkipSelf() private rsAppInitializerModule: RsWebComponentAppInitializerModule
  ) {
    if (rsAppInitializerModule) {
      throw new Error('RsAppInitializerModule is already loaded. Import it only once!');
    }

    this.APP_INITIALIZER_HTML_ELEMENT_NAME = this.injector.get(RS_WEB_COMP_APP_INITIALIZER_HTML_ELEMENT_NAME)
    this.htmlElement = document.createElement(this.APP_INITIALIZER_HTML_ELEMENT_NAME) as unknown as AppInitializerHtmlElement

    this.registerNewCustomElementAppInitializer();
    this.addHtmlElementToDom();
  }

  private addHtmlElementToDom(): void {
    document?.querySelector('body')?.appendChild(this.htmlElement);
  }

  private registerNewCustomElementAppInitializer(): void {
    customElements.define(
      this.APP_INITIALIZER_HTML_ELEMENT_NAME,
      createCustomElement(
        AppInitializerComponent,
        {
          injector: this.injector
        }
      )
    );
  }

}
