import * as Sentry from '@sentry/angular-ivy';
import { Environment } from '../models';

export class RsSentry {

  private _denyUrls: (string | RegExp)[] | undefined = ['/cdn.rentasolutions.org/topbar'];

  constructor(
    public readonly options: {
      dsn: string,
      enabled: boolean,
      environment: Environment,
      sentryReleaseName: string,
      denyUrls?: (string | RegExp)[] | undefined,
      beforeSend?: (event: Sentry.Event, hint: Sentry.EventHint) => PromiseLike<Sentry.Event | null> | Sentry.Event | null
    }
  ) {
  }

  public sentryInit(): void {
    Sentry.init({
      dsn: this.options.dsn,
      enabled: this.options.enabled,
      debug: (['test'] as Environment[]).includes(this.options.environment),
      environment: this.options.environment,
      release: this.options.sentryReleaseName,
      ignoreErrors: [/^Non-Error exception captured/],
      denyUrls: this._denyUrls?.concat(this.options.denyUrls || []),
      beforeSend: (event: Sentry.Event, hint: Sentry.EventHint) => {
        const status = Number((event.extra?.error as { status: number })?.['status']);
        if ( event.message === 'http-error' && [401, 403, 404].includes(status)) {
          return null;
        }

        if (this.options.beforeSend) {
          return this.options.beforeSend(event, hint);
        }

        return event;
      }
    });
  }
}

