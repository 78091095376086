import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, shareReplay } from 'rxjs';
import { UserInfo, UserPlateHolderAccessInfo } from '@shared/models/user';
import { map } from 'rxjs/operators';
import {
  SKIP_RS_MESSAGE_HANDLER_ERRORS_CONTEXT
} from '@rs-shared-lib/modules/rs-core/interceptors/rs-http-error-interceptor/http-context-tokens/skip-rs-message-handler-errors';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly getUserPlateHolderAccessInfo$: Observable<UserPlateHolderAccessInfo[]>;
  private readonly activeUserPlateHolderAccessInfo$: Observable<UserPlateHolderAccessInfo[]>;

  constructor(private http: HttpClient) {
    this.getUserPlateHolderAccessInfo$ = this.http.get<UserPlateHolderAccessInfo[]>(`${environment.apiUrl}/plateholder`)
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true
        })
      );

    this.activeUserPlateHolderAccessInfo$ =  this.getUserPlateHolderAccessInfo$.pipe(
      map((userPlateHolderAccessInfo) => userPlateHolderAccessInfo.filter(userPlateHolderAccessInfo => userPlateHolderAccessInfo.active)),
      map((userPlateHolderAccessInfo) => userPlateHolderAccessInfo.sort((
        userPlateHolderAccessInfoA,
        userPlateHolderAccessInfoB
      ) => userPlateHolderAccessInfoA.name.localeCompare(userPlateHolderAccessInfoB.name))),
      shareReplay({
        bufferSize: 1,
        refCount: true
      })
    );
  }

  public readonly getUserInfo = (): Observable<UserInfo> =>  {
    return this.http.get<UserInfo>(`${environment.apiUrl}/me`, {
      context: SKIP_RS_MESSAGE_HANDLER_ERRORS_CONTEXT
    });
  }

  public readonly getUserPlateHolderAccessInfo = (): Observable<UserPlateHolderAccessInfo[]> => {
    return this.getUserPlateHolderAccessInfo$;
  }

  public readonly getActiveUserPlateHolderAccessInfo = (): Observable<UserPlateHolderAccessInfo[]> => {
    return this.activeUserPlateHolderAccessInfo$;
  }
}
