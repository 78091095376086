import { createReducer, on } from '@ngrx/store';
import { scanListTableOptions } from '@scans/scan-list/state/scan-list.actions';
import { TableOptions } from '@rs-shared-lib/abstract-classes/ovevriews/models/models';
import { GetScanSortByEnum } from '@shared/models/get-scan';

export const scanListStateFeatureKey = 'scanList';

export interface ScanListState {
	tableOptions: TableOptions<keyof typeof GetScanSortByEnum>;
}

export const initialScanListState: ScanListState = {
  tableOptions: {
    sort: {
      active: '',
      direction: ''
    },
    pageEvent: {
      length: 1,
      pageIndex: 0,
      pageSize: 5,
      previousPageIndex: 1
    },
    resultCount: 0
  }
};

export const scanListReducer = createReducer(
  initialScanListState,
  on(
    scanListTableOptions,
    (state, {
      tableOptions
    }) => {
      return {
        ...state,
        tableOptions: tableOptions
      };
    }
  )
);
